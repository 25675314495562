import React from "react";
import Link from "next/link";
import { authServices } from "../../services/authServices";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import { fetchAdminDetails } from "../../redux/features/userSlice";
import { noticeServices } from "@/services/noticeServices";
import { setNoticeStatus } from "../../redux/features/userSlice";

function Header() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { pathname, push } = useRouter();
  const details = useSelector((state) => state.user.adminDetails);
  const statusNotice = useSelector((state) => state.user.noticeStatus);
  const { name, email, mobile, address, status } = details;
  const [noticeList, setNoticeList] = useState([]);
  const [card, setCard] = useState(null);
  const goBack = () => {window.history.back();};
  const heading = card != null ? Object?.keys(card)?.filter((i) => i == pathname) : "";

  useEffect(() => {
    dispatch(fetchAdminDetails());
    noticeListing({});
  }, []);

  useEffect(() => {
    if (statusNotice) {
      noticeListing({});
      dispatch(setNoticeStatus(false));
    }
  }, [statusNotice]);

  useEffect(() => {
    fetchData();   
  }, []);

  const fetchData = async () => {
    const response = await fetch('/api/get-pages-name')
    const data = await response.json();
    setCard(data);
  }
 
  // for notice board listing
  const noticeListing = async (params) => {
    await noticeServices
      .noticeListing(params)
      // await axios
      //   .get("/list-notice", { withCredentials: true })
      .then((response) => {
        const list = response.data;
        setNoticeList(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //logout function
  const logOut = async () => {
    const callbackUrl = "/auth/login";
    await authServices
      .logoutAuth()
      .then((response) => {
        localStorage.clear();
        sessionStorage.clear();
        toast.success("successfully logout");
        if (response.message == "Success") {
          signOut({ callbackUrl });
        }
      })
      .catch((error) => {
        console.log(error);
      });     
  };

  return (
    <>
      <header className="pc-header">
        <div className="header-wrapper">
          <div className="me-auto pc-mob-drp">
            <ul className="list-unstyled">
              <li className="pc-h-item pc-sidebar-collapse">
                <Link
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="pc-head-link ms-0"
                  id="sidebar-hide"
                >
                  <i className="ti ti-menu-2"></i>
                </Link>
              </li>
              <li className="pc-h-item pc-sidebar-popup">
                <Link
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="pc-head-link ms-0"
                  id="mobile-collapse"
                >
                  <i className="ti ti-menu-2"></i>
                </Link>
              </li>
              {/* <li className="pc-h-item d-md-inline-flex">
                <h4 className="mb-0">Dashboard</h4>
              </li> */}
            </ul>
          </div>
          <div className="ms-auto">
            <ul className="list-unstyled">
              {/* <li className="dropdown pc-h-item"><Link className="pc-head-link dropdown-toggle arrow-none me-0" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false"><svg className="pc-icon">
                                <use xlinkHref="#custom-sun-1"></use>
                            </svg></Link>
                        <div className="dropdown-menu dropdown-menu-end pc-h-dropdown"><Link href="#!" className="dropdown-item" onClick={()=>layout_change('dark')}><svg className="pc-icon">
                                    <use xlinkHref="#custom-moon"></use>
                                </svg> <span>Dark</span> </Link><Link href="#!" className="dropdown-item" onClick={()=>layout_change('light')}><svg className="pc-icon">
                                    <use xlinkHref="#custom-sun-1"></use>
                                </svg> <span>Light</span> </Link><Link href="#!" className="dropdown-item" onClick={()=>layout_change_default()}><svg className="pc-icon">
                                    <use xlinkHref="#custom-setting-2"></use>
                                </svg> <span>Default</span></Link></div>
                    </li> */}
              <li class="dropdown pc-h-item">
                <a
                  class="pc-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                  title="View Notices"
                >
                  <svg class="pc-icon">
                    <use xlinkHref="#custom-notice"></use>
                  </svg>{" "}
                  <span class="badge bg-success pc-h-badge">
                    {
                      noticeList?.data?.filter(
                        (item) =>
                          new Date(item?.end_date) >= new Date() &&
                          item?.status != 0
                      )?.length
                    }
                  </span>
                </a>
                <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown">
                  <div class="dropdown-header d-flex align-items-center justify-content-between" >
                    <h5 class="m-0">Notices</h5>
                  </div>
                  <div
                    class="dropdown-body text-wrap header-notification-scroll position-relative"
                    style={{ maxHeight: "calc(100vh - 215px)" }}
                  >
                    <div class="card mb-2">
                      {noticeList && noticeList?.data?.length != 0
                        ? noticeList?.data?.map((item, index) => {
                            if (
                              new Date(item?.end_date) >= new Date() &&
                              item?.status != 0
                            ) {
                              return (
                                <div class="card-body">
                                  <div class="d-flex">
                                    <div class="flex-shrink-0">
                                      <svg class="pc-icon text-primary">
                                        <use xlinkHref="#custom-sms"></use>
                                      </svg>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                      <h5 class="text-body mb-2">
                                        {item.title}
                                      </h5>
                                      <p class="mb-0">
                                        {item.description.split(" ").length > 15
                                          ? `${item.description
                                              .split(" ")
                                              .slice(0, 15)
                                              .join(" ")}...`
                                          : item.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              ("No Records Found");
                            }
                          })
                        : "No Records Found"}
                    </div>
                  </div>
                  <div class="text-center py-2">
                    <Link href="/notice/list">View all Notices</Link>
                  </div>
                </div>
              </li>
              <li className="dropdown pc-h-item header-user-profile">
                <Link
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <img
                    src="/images/user/avatar-2.jpg"
                    alt="user-image"
                    className="user-avtar"
                  />
                </Link>
                <div className="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                  <div className="dropdown-header d-flex align-items-center justify-content-between py-2">
                    <h5 className="m-0">Profile</h5>
                  </div>
                  <div className="dropdown-body p-2">
                    <div
                      className="profile-notification-scroll position-relative"
                      style={{ maxHeight: "calc(100vh - 225px)" }}
                    >
                      <div className="d-flex mb-1 align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src="/images/user/avatar-2.jpg"
                            alt="user-image"
                            className="user-avtar wid-35"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <Link href="/user" className="mb-1">
                            {name ?? "Not Specified"}
                          </Link>
                          <span>
                            {/* <Link href="cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="33505241405c5d1d575241415a5d73505c5e43525d4a1d5a5c">[email&#160;protected]</Link> */}
                          </span>
                        </div>
                      </div>
                      <hr className="border-secondary border-opacity-50" />
                      {/* <div className="card">
                                        <div className="card-body py-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h5 className="mb-0 d-inline-flex align-items-center"><svg className="pc-icon text-muted me-2">
                                                        <use xlinkHref="#custom-notification-outline"></use>
                                                    </svg>Notification</h5>
                                                <div className="form-check form-switch form-check-reverse m-0"><input className="form-check-input f-18" type="checkbox" role="switch"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                      <p className="text-span"></p>
                      <Link href="/user" className="dropdown-item py-2">
                        <span>
                          <i className="ti ti-user"></i>
                          <span>My Profile</span>
                        </span>
                      </Link>
                      <hr className="border-secondary border-opacity-50" />
                      {/* <p className="text-span">Team</p><Link href="#" className="dropdown-item"><span><svg className="pc-icon text-muted me-2">
                                                <use xlinkHref="#custom-profile-2user-outline"></use>
                                            </svg> <span>UI Design team</span></span>
                                        <div className="user-group"><img src="/images/user/avatar-1.jpg" alt="user-image" className="avtar"/> <span className="avtar bg-danger text-white"/>K <span className="avtar bg-success text-white"><svg className="pc-icon m-0">
                                                    <use xlinkHref="#custom-user"></use>
                                                </svg> </span><span className="avtar bg-light-primary text-primary">+2</span></div>
                                    </Link><Link href="#" className="dropdown-item"><span><svg className="pc-icon text-muted me-2">
                                                <use xlinkHref="#custom-profile-2user-outline"></use>
                                            </svg> <span>Friends Groups</span></span>
                                        <div className="user-group"><img src="/images/user/avatar-1.jpg" alt="user-image" className="avtar"/> <span className="avtar bg-danger text-white"/>K <span className="avtar bg-success text-white"><svg className="pc-icon m-0">
                                                    <use xlinkHref="#custom-user"></use>
                                                </svg></span></div>
                                    </Link><Link href="#" className="dropdown-item"><span><svg className="pc-icon text-muted me-2">
                                                <use xlinkHref="#custom-add-outline"></use>
                                            </svg> <span>Add new</span></span>
                                        <div className="user-group"><span className="avtar bg-primary text-white"><svg className="pc-icon m-0">
                                                    <use xlinkHref="#custom-add-outline"></use>
                                                </svg></span></div>
                                    </Link> */}

                      <div className="d-grid mb-3">
                        <button
                          type="button"
                          onClick={() => logOut()}
                          className="btn btn-primary"
                        >
                          <i className="ti ti-logout align-middle me-2"></i>
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div className="mobile-Header">
          <div className="inner-header">
                <div className="backbtn" onClick={goBack}><i class="ti ti-chevron-left"></i></div>  
                <div className="mobile-title"><h4>{card != null ? card[heading[0]]: ""}</h4></div>                                    
          </div>                         
      </div>
    </>
  );
}

export default Header;
