import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const noticeServices = {
  addNotice: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_NOTICE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addNotice].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

noticeListing: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.NOTICE_LISTING}?length=${params.length?params.length:10}&search=${params.search?params.search:""}&page=${params.page?params.page:1}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.noticeListing].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  editStaff: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_STAFF}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editStaff].handleRequestCancellation()
          .signal
        : undefined,
    });
  
    // returning the data
    return response;
  },

  deleteNotice: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_NOTICE}/${params}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.deleteNotice].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

updateNotice: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_NOTICE}/${params.id}`,
      method: "POST",
      data: params.fData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateNotice].handleRequestCancellation()
          .signal
        : undefined,
    });
    // returning the data
    return response;
},

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(noticeServices);
