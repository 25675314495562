import { api } from "@/utils/axiosConfigs";
import { defineCancelApiObject } from "@/utils/axiosUtils";
import { ENDPOINTS } from "@/utils/endpoints";

export const authServices = {
    loginAuth: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.LOGIN}`,
          method: "POST",
          data: params,
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.loginAuth].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      emailVerify: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.EMAIL_VERIFY}?${params}`,
          method: "GET",
          //data: params,
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.emailVerify].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      forgetAuth: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.FORGET_PASSWORD}`,
          method: "POST",
          data: params,
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.forgetAuth].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },

      logoutAuth: async function (params, cancel = false) {
        const response = await api.request({
          url: `${ENDPOINTS.LOGOUT}`,
          method: "POST",
          // retrieving the signal value by using the property name
          signal: cancel
            ? cancelApiObject[this.logoutAuth].handleRequestCancellation()
              .signal
            : undefined,
        });
    
        // returning the data
        return response.data;
      },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(authServices);